import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { useStaticQuery, graphql, Link } from "gatsby";
import TextPostPreview from '../components/TextPostPreview';
import ImagePostPreview from '../components/ImagePostPreview';
import Seo from '../components/Seo'

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const Grid = styled.main`
  display: grid;
  grid-template-columns: repeat(3, minmax(400px, 1fr));
`

const Works = ({data}) => {
  const posts = data?.allMarkdownRemark.edges

  return (
    <>
      <Seo title="Nigel Lendon - Works" description="A collection of Nigel's past and present works ranging from 1967 - 2020's."/>
      <Layout>
        <ResponsiveMasonry>
          <Masonry>
            {posts.map(({ node }, i) => (
              <ImagePostPreview key={i} critical={i < 4} {...node} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ImagePosts {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "image-post"}}}, sort: {fields: frontmatter___year, order: ASC}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            year
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;


export default Works