import React from 'react'
import styled from "styled-components";
import { Link } from "gatsby";
import Img from 'gatsby-image'
import { title } from 'process';

const Title = styled.h4`
  text-transform: uppercase;
  font-size: 1.2em;
  padding-right: 1rem;
`;

const ImagePost = styled.article`
  display: flex;
  padding: 1rem;
  flex-direction: column;
`;

const ImagePostHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Date = styled.span`
  font-style: italic;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;


export const ImagePostPreview = ({fields, frontmatter, critical}) => {
  return (
    <ImagePost key={title}>
      <Link to={fields.slug}>
        <Img fadeIn={!critical} critical={critical} fluid={frontmatter?.featuredimage?.[0]?.childImageSharp?.fluid} />
      </Link>
      <ImagePostHead>
        <StyledLink to={fields.slug}>
          <Title>{frontmatter.title}</Title>
        </StyledLink>
        <Date>{frontmatter.year}</Date>
      </ImagePostHead>
    </ImagePost>
  )
}

export default ImagePostPreview